import css from './JoinRequestSuccessPopup.module.css';
const JoinRequestSuccess = ({ status, setOpen, joinMarketMessage }) => {
  return (
    <div className={css.mainPopup}>
      <div className={css.tickgreen}>
        {status !== "profile"
          ?
          <div className={css.successIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>

          </div>
          :
          <div className={css.errorIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
            </svg>
          </div>
        }
      </div>
      <h2 className='' style={{ color: (status === "profile") ? "#f00" : "#069d46" }}>
        {/* {joinMarketMessage === 'Failed to fetch'
          ? 'Joining Request Already Sent'
          : 'Joining Request Sent Successfully'} */}
        {joinMarketMessage} {(status === "profile") ? <a href='/account/contact-details'>click here</a> : ""}
      </h2>
      <button onClick={() => setOpen(false)} className={css.closebtn}>
        close
      </button>
    </div>
  );
};
export default JoinRequestSuccess;
