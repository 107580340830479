import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { getMarketRequests, requestMarketAndStoreUpdateApi } from '../../util/api';

// ================ Action types ================ //
export const FETCH_MARKETDATA_SUCCESS = 'app/MarketDashboardPage/FETCH_MARKETDATA_SUCCESS';
export const FETCH_MARKET_REQUEST_SUCCESS = 'app/MarketDashboardPage/FETCH_MARKET_REQUEST_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
  marketDashboardData: [],
  marketDashboardrequests: [],

};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_MARKETDATA_SUCCESS:
      return { ...state, marketDashboardData: payload };

    case FETCH_MARKET_REQUEST_SUCCESS:
      return { ...state, marketDashboardrequests: payload };

    default:
      return state;
  }
}
export const fetchData = () => (dispatch, getState, sdk) => {
  sdk.ownListings.query({}).then(res => {
    dispatch(fetchMarketDataSuccess(res));
    return res?.data;
    // res.data contains the response data
  });
};

export const fetchmarketRequest = () => (dispatch, getState, sdk) => {
  return getMarketRequests().then(res => {
    console.log('resss>>', res.data.data);
    dispatch(fetchMarketRequestSuccess(res.data.data))
    return res;
  });
};

// ================ Selectors ================ //

// ================ Action creators ================ //
export const fetchMarketDataSuccess = marketDashboardData => ({
  type: FETCH_MARKETDATA_SUCCESS,
  payload: marketDashboardData,
});
export const fetchMarketRequestSuccess = marketDashboardrequests => ({
  type: FETCH_MARKET_REQUEST_SUCCESS,
  payload: marketDashboardrequests,
});

export const loadData = () => dispatch => {
  return Promise.all([dispatch(fetchData()), dispatch(fetchmarketRequest())]).then(response => {
    return response;
  });
};


export const requestMarketAndStoreUpdate = (data) => dispatch => {

  return requestMarketAndStoreUpdateApi(data)
    .then(response => {
      return response
    })
    .catch(e => {
      return storableError(e)
    });
}

