import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';
import { MARKET_LISTING } from '../../util/listingConstant';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ensureCurrentUser } from '../../util/data';

const UserNavTab = props => {
  const { className, rootClassName, currentUser, currentPage } = props;
  const classes = classNames(rootClassName || css.root, className);
  const user = ensureCurrentUser(currentUser);
  const userType = user?.attributes?.profile?.protectedData?.userType;
  console.log(userType, 'userTypeuserTypeuserTypeuserTypeuserTypeuserType');
  const tabs = [
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];
  const MarketTab =
    userType === MARKET_LISTING
      ? [
          //   {
          //   text: <FormattedMessage id="UserNav.marketDashboard" />,
          //   selected: currentPage === 'MarketDashboardPage',
          //   linkProps: {
          //     name: 'MarketDashboardPage',
          //   },
          // }
        ]
      : [];
  return (
    <LinkTabNavHorizontal
      className={classes}
      tabRootClassName={css.tab}
      tabs={[...MarketTab, ...tabs]}
      skin="dark"
    />
  );
};

UserNavTab.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNavTab.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  return { currentUser };
};

const UserNav = compose(connect(mapStateToProps), injectIntl)(UserNavTab);
export default UserNav;
