import { storableError } from '../../util/errors';
import { getStoreDataRequest } from '../../util/api';

// ================ Action types ================ //
export const FETCH_STORE_PRODUCTS = '/api/get-store-data/65de2563-beb7-4682-94b3-8239d0c52fbd';

// ================ Reducer ================ //

const initialState = {
  storeProductsData: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_STORE_PRODUCTS:
      return { ...state, storeProductsData: payload };
    default:
      return state;
  }
}

const fetchStoreDataRequest = id => (dispatch, getState, sdk) => {
  return getStoreDataRequest(id).then(res => {
    console.log('resss>>', res);
    dispatch(fetchStoreDataSuccess(res));
    return res;
  });
};

// ================ Selectors ================ //

// ================ Action creators ================ //
export const fetchStoreDataSuccess = storeProductsData => ({
  type: FETCH_STORE_PRODUCTS,
  payload: storeProductsData,
});

export const loadData = params => dispatch => {
  const id = params.id;
  return Promise.all([dispatch(fetchStoreDataRequest(id))]).then(response => {
    return response;
  });
};

// export const requestMarketAndStoreUpdate = data => dispatch => {
//   return getStoreDataRequest()
//     .then(response => {
//       return response;
//     })
//     .catch(e => {
//       return storableError(e);
//     });
// };
